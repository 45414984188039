(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/loader-react/assets/javascripts/loader.js') >= 0) return;  svs.modules.push('/components/components/loader-react/assets/javascripts/loader.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }


(function (svs, React, PropTypes) {
  const SHOW_DELAY_MS = 200;
  const defaultSpinnerOptions = {
    lines: 12,
    length: 18,
    width: 5,
    radius: 12,
    scale: 1.0,
    corners: 1,
    opacity: 0.25,
    rotate: 0,
    direction: 1,
    speed: 1,
    trail: 60,
    fps: 20,
    zIndex: 2e9,
    className: 'spinner',
    top: '50%,',
    left: '50%',
    shadow: false,
    hwaccel: true,
    position: 'absolute' 
  };
  const sizes = {
    100: {
      radius: 2,
      length: 3,
      width: 2,
      lines: 8
    },
    200: {
      radius: 4,
      length: 6,
      width: 2,
      lines: 10
    },
    300: {
      radius: 8,
      length: 12,
      width: 4,
      lines: 12
    }
  };
  class Loader extends React.Component {
    constructor(props) {
      super(props);
      this.spinnerElement = null;
      this.spinner = null;
      this.timeOutId = null;
      this.getSpinnerOptions = this.getSpinnerOptions.bind(this);
      this.showSpinner = this.showSpinner.bind(this);
    }
    componentDidMount() {
      if (this.props.delay) {
        this.timeOutId = setTimeout(this.showSpinner, SHOW_DELAY_MS);
      } else {
        this.showSpinner();
      }
    }
    componentWillUnmount() {
      if (this.spinner) {
        this.spinner.stop();
      }
      clearTimeout(this.timeOutId);
      this.timeOutId = null;
    }
    getSpinnerOptions() {
      const {
        size,
        inverted
      } = this.props;
      return _objectSpread(_objectSpread({}, defaultSpinnerOptions), {}, {
        color: inverted ? '#323233' : '#e1e1e5',
        radius: sizes[size].radius,
        length: sizes[size].length,
        width: sizes[size].width,
        lines: sizes[size].lines
      });
    }
    showSpinner() {
      this.spinner = new Spinner(this.getSpinnerOptions()).spin(this.spinnerElement);
    }
    render() {
      const containerClass = this.props.className ? "".concat(this.props.className, "-loader-container") : 'loader-container-react';
      const spinnerClass = this.props.className ? "".concat(this.props.className, "-loader") : 'loader-react';
      return React.createElement("div", {
        className: containerClass
      }, React.createElement("div", {
        className: spinnerClass,
        ref: spinner => {
          this.spinnerElement = spinner;
        }
      }));
    }
  }
  Loader.defaultProps = {
    className: '',
    delay: true,
    inverted: false,
    size: 300
  };
  svs.components.ReactLoader.Loader = Loader;
})(svs, React, PropTypes);

 })(window);